import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="w-full bg-gray-100 py-4">
      <div className="container mx-auto flex flex-wrap justify-between items-center">
        {/* Contacto Principal */}
        <div className="flex flex-col text-center md:text-left">
          <p className="text-xl font-semibold text-custom-light-blue mb-2">
            Contáctanos
          </p>
          <div className="text-sm text-custom-light-blue">
            <p>📍 Calle 124 #45-15</p>
            <p>
              📞{' '}
              <a
                href="tel:+573044575182"
                className="text-custom-blue hover:underline"
              >
                +57 304 4575182
              </a>
            </p>
            <p>
              📞{' '}
              <a
                href="tel:+573108167555"
                className="text-custom-blue hover:underline"
              >
                +57 310 8167555
              </a>
            </p>
            <p>
              ✉️{' '}
              <a
                href="mailto:director.ventas@yesbpo.co"
                className="text-custom-blue hover:underline"
              >
                director.ventas@yesbpo.co
              </a>
            </p>
          </div>
        </div>

        {/* Redes Sociales */}
        <div className="flex space-x-4">
          <a
            href="https://www.facebook.com/yesbpo.co/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-2xl text-blue-600 hover:text-blue-400 transition-colors duration-300 transform hover:scale-110"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href="https://www.instagram.com/yesbpo/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-2xl text-pink-500 hover:text-pink-400 transition-colors duration-300 transform hover:scale-110"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://x.com/yesbposas"
            target="_blank"
            rel="noopener noreferrer"
            className="text-2xl text-black hover:text-gray-700 transition-colors duration-300 transform hover:scale-110"
          >
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
        </div>

        {/* Derechos Reservados */}
        <div className="text-sm text-custom-light-blue">
          <p>
            ©{' '}
            <a
              href="https://yesbpo.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-green-500 transition-colors"
            >
              YES CONTACT & BPO SAS
            </a>{' '}
            ©{' '}
            <a
              href="https://www.appcenteryes.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-green-500 transition-colors"
            >
              4 YES SAS
            </a>{' '}
            - 2025
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
