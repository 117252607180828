import React from 'react';
import { motion } from 'framer-motion';
const DataApp = () => {
  const features = [
    {
      title: 'Descubre el Potencial de Tu Equipo con un Monitoreo Avanzado',
      description:
        'Optimiza el rendimiento de tu equipo con herramientas avanzadas y métricas detalladas. Obtén insights claros sobre tiempos de respuesta, interacción, volumen de conversaciones, y descarga historiales en PDF o en exportes para un monitoreo integral o individual. Mejora cada aspecto de la atención al cliente para garantizar una experiencia excepcional.',
      image:
        'https://www.yesbpo.com/wp-content/uploads/2025/01/WhatsApp-Image-2025-01-24-at-8.59.47-AM-2.jpeg',
    },
    {
      title: 'Crea, Controla y Optimiza tus Plantillas de Forma Directa',
      description: `

Mejora el rendimiento de tu equipo con métricas claras y herramientas avanzadas. Supervisa tiempos de respuesta, interacción y volumen de conversaciones. Descarga historiales en PDF o en Exportes y realiza un monitoreo integral o individualizado para garantizar una atención al cliente excepcional.`,
      image:
        'https://www.yesbpo.com/wp-content/uploads/2025/01/WhatsApp-Image-2025-01-24-at-8.59.47-AM-1.jpeg',
    },
    {
      title: 'Automatización Inteligente: La Clave para Elevar tu Negocio',
      description:
        'Nuestro bot se adapta en tiempo real, consultando datos desde tu base de datos y ajustando flujos de conversación según tus necesidades. Automatiza respuestas, mejora la eficiencia y ofrece una experiencia profesional que fideliza usuarios. Ideal para retail, finanzas y telecomunicaciones.',
      image:
        'https://www.yesbpo.com/wp-content/uploads/2025/01/WhatsApp-Image-2025-01-21-at-4.55.43-PM.jpeg',
    },
    {
      title:
        'Potencia la Gestión de tu Equipo con una Herramienta de Comunicación Avanzada',
      description: (
        <>
          Mejora la atención al cliente con nuestro{' '}
          <a
            href="https://business.whatsapp.com/blog/online-conversations-offline-commerce"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 underline"
          >
            chat sin límites
          </a>{' '}
          de usuarios, plantillas proactivas, respuestas predefinidas y métricas
          en tiempo real. Accede a historiales y estados para garantizar un
          servicio eficiente y profesional que fideliza en cada interacción.
        </>
      ),
      image:
        'https://www.yesbpo.com/wp-content/uploads/2025/01/WhatsApp-Image-2025-01-24-at-8.59.47-AM.jpeg',
    },
    {
      title: 'Transforma tu Gestión con Reportes Detallados y Completos',
      description:
        'Nuestra plataforma te permite descargar historiales completos y resúmenes detallados de campañas de WhatsApp, con un desglose mensaje por mensaje, ofreciendo claridad y control sobre tus interacciones.',
      image:
        'https://www.yesbpo.com/wp-content/uploads/2025/01/WhatsApp-Image-2025-01-24-at-8.59.46-AM.jpeg',
    },
    {
      title: 'Perfil personalizado de whatsapp',
      description: (
        <>
          Nuestra plataforma permite personalizar perfiles de{' '}
          <a
            href="https://faq.whatsapp.com/577829787429875/?locale=es_LA&category=5245246"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 underline"
          >
            WhatsApp
          </a>{' '}
          desde la aplicación de forma ágil, asegurando conversaciones alineadas
          con tu marca y una experiencia profesional para tus clientes.
        </>
      ),
      image:
        'https://www.yesbpo.com/wp-content/uploads/2025/01/WhatsApp-Image-2025-01-24-at-8.59.48-AM.jpeg',
    },
  ];

  return (
    <div className="max-w-7xl mx-auto p-6 bg-white min-h-screen font-poppins">
      <h1 className="text-4xl font-bold text-center mb-16 text-gray-800">
        Obtenga lo mejor de AppCenter 4.0
      </h1>

      <div className="space-y-24">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className={`flex flex-col ${
              index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
            } gap-8 items-center`}
          >
            {/* Image Section */}
            <div className="w-full md:w-1/2">
              <motion.div
                className="relative rounded-2xl overflow-hidden shadow-2xl"
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.3 }}
              >
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 mix-blend-overlay"></div>
              </motion.div>
            </div>

            {/* Content Section */}
            <div className="w-full md:w-1/2 space-y-6">
              <motion.h2
                className="text-3xl font-bold text-gray-800"
                initial={{ opacity: 0, x: index % 2 === 0 ? 50 : -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                viewport={{ once: true }}
              >
                {feature.title}
              </motion.h2>
              <motion.p
                className="text-lg text-gray-600 leading-relaxed"
                initial={{ opacity: 0, x: index % 2 === 0 ? 50 : -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
                viewport={{ once: true }}
              >
                {feature.description}
              </motion.p>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Background Decorative Elements */}
      <div className="fixed top-1/4 left-1/2 w-96 h-96 bg-blue-500/5 rounded-full blur-3xl -z-10 transform -translate-x-1/2"></div>
      <div className="fixed bottom-1/4 right-1/4 w-96 h-96 bg-purple-500/5 rounded-full blur-3xl -z-10"></div>
    </div>
  );
};
export default DataApp;
