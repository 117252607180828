import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import HeaderA from './apartados/NOPE/header';
import Marcas from './apartados/Marcas';
import Land from './apartados/land';
import CorreosN from './apartados/correo';
import FooterN from './apartados/footer';
import { ChevronUp, ChevronDown, Mail, Phone, X, Contact } from 'lucide-react';
import Compara from './apartados/compara';
import DataApp from './apartados/NOPE/dataapp';

function App() {
  const [contacto, setContacto] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const [ModalMail, setModalMail] = useState(false);
  const [ModalLand, setModalLand] = useState(true);
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    document.title = 'WHATSAPP APPCENTER';

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      setIsAtTop(scrollTop === 0);
      setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 10);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const smoothScroll = targetPosition => {
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    const duration = 1000;
    let start = null;

    const step = timestamp => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const percentComplete = Math.min(progress / duration, 1);

      const easeInOutCubic =
        percentComplete < 0.5
          ? 4 * percentComplete ** 3
          : 1 - (-2 * percentComplete + 2) ** 3 / 2;

      window.scrollTo(0, startPosition + distance * easeInOutCubic);

      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  };

  return (
    <div className="bg-white min-h-screen relative overflow-hidden">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        {/* Círculo */}
        <div className="bg-blue-500 rounded-full w-64 h-64 opacity-20 absolute top-10 left-10"></div>
        {/* Triángulo */}
        <div className="w-0 h-0 border-l-[50px] border-l-transparent border-r-[50px] border-r-transparent border-b-[100px] border-b-blue-500 opacity-20 absolute bottom-20 right-20"></div>
        {/* Cuadrado */}
        <div className="bg-green-500 w-40 h-40 opacity-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
      </div>
      <div ref={sectionRefs[0]}>
        <HeaderA />
      </div>
      <div ref={sectionRefs[1]}>
        <Marcas />
      </div>

      <div ref={sectionRefs[4]}>
        <DataApp />
      </div>
      <div ref={sectionRefs[3]}>
        <Compara />
      </div>

      <div ref={sectionRefs[6]}>
        <FooterN />
      </div>
      {!contacto && (
        <div className="fixed bottom-4 right-4 flex flex-col space-y-4 z-50">
          <button
            onClick={() => setContacto(true)}
            className="bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-all duration-300"
          >
            <Contact size={24} />
          </button>
        </div>
      )}
      {contacto && (
        <div className="fixed bottom-4 right-4 flex flex-col space-y-4 z-50">
          <button
            onClick={() => setContacto(false)}
            className="bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-all duration-300"
          >
            <a
              href="tel:+573044575182"
              className="text-custom-blue hover:underline"
            >
              <Phone size={24} />
            </a>
          </button>

          <button
            onClick={() => setContacto(false)}
            className="bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-all duration-300"
          >
            <a
              href="mailto:director.ventas@yesbpo.co"
              className="text-custom-blue hover:underline"
            >
              <Mail size={24} />
            </a>
          </button>

          <a
            onClick={() => setContacto(false)}
            href="https://api.whatsapp.com/send/?phone=573214743580&text=Hola%2C+quiero+ponerme+en+contacto+con+un+asesor+de+Comercial+para+realizar+algunas+consultas.&type=phone_number&app_absent=02"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-500 text-white p-2 rounded-full shadow-lg hover:bg-green-600 transition-all duration-300"
          >
            <img
              src="https://www.yesbpo.com/wp-content/uploads/2025/01/whatsapp-873316_1280.png"
              className="w-10"
              alt="WhatsApp"
            />
          </a>
        </div>
      )}

      {ModalLand && (
        <div
          style={{ overflow: 'auto' }}
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        >
          <div
            style={{
              maxWidth: '90vw',
              maxHeight: '90vh',
              overflowY: 'auto',
            }}
            className="bg-white  rounded-lg relative"
          >
            <button
              onClick={() => setModalLand(false)}
              className="btn btn-circle fixed top-10  text-3xl text-gray-500 hover:text-gray-700"
            >
              <X size={24} />
            </button>
            <Land />
          </div>
        </div>
      )}

      {ModalMail && (
        <div
          style={{ overflow: 'scroll' }}
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        >
          <div className="bg-white p-4 rounded-lg relative">
            <CorreosN />
            <p className="mt-4 text-center text-gray-700">Contactanos</p>
            <button
              onClick={() => setModalMail(false)}
              className="absolute top-6 right-8 text-3xl mt-4 text-gray-500 md:lg-52  hover:text-gray-700"
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
