import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import '@fontsource/montserrat/300.css'; // Peso 300 (ligero)
import '@fontsource/montserrat/400.css'; // Peso 400 (normal)
import '@fontsource/montserrat/700.css'; // Peso 700 (negrita)
import CorreosN from './correo';
const Marcas = () => {
  const [ModalMail, setModalMail] = useState(false);

  const textos = [
    'AppCenter 4.0',
    'La solución más completa para potenciar su negocio',
    'Gestión Masiva de WhatsApp',
    'Monitoreo en tiempo real',
    'Automatización de mensajes con Bots',
  ];
  const [textoActual, setTextoActual] = useState(textos[0]);
  const [indice, setIndice] = useState(0);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setIndice(indicePrevio => (indicePrevio + 1) % textos.length);
    }, 3000);

    return () => clearInterval(intervalo);
  }, [textos.length]);

  useEffect(() => {
    setTextoActual(textos[indice]);
  }, [indice, textos]);

  return (
    <div className="relative overflow-hidden items-center w-full ">
      {/* Modal */}
      {ModalMail && (
        <div
          style={{ overflow: 'auto' }}
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 "
        >
          <div
            style={{
              maxWidth: '90vw',
              maxHeight: '90vh',
              overflowY: 'auto',
            }}
            className="bg-white  rounded-lg relative border-2 border-blue-500"
          >
            <button
              onClick={() => setModalMail(false)}
              className="btn btn-circle fixed top-10  text-3xl text-gray-500 hover:text-gray-700"
            >
              <X size={24} />
            </button>
            <CorreosN />
          </div>
        </div>
      )}

      {/* Main Content */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="flex flex-col md:flex-row shadow-lg justify-center items-center rounded-lg bg-white xxxxxxxxxxxxl md:p-16 "
      >
        {/* Text Section */}
        <motion.div
          className="flex flex-col justify-center items-center md:items-start gap-6 w-full md:w-1/2"
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <AnimatePresence mode="wait">
            <motion.h1
              key={textoActual}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="text-2xl md:text-4xl font-bold text-blue-500 hover:text-blue-600 transition-colors duration-300 font-poppins text-center md:text-left"
            >
              {textoActual}
            </motion.h1>
          </AnimatePresence>
        </motion.div>

        {/* Video Section */}
        <motion.div
          className="w-full md:w-1/2 flex justify-center mt-6 md:mt-0"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          whileHover={{ scale: 1.05 }}
        >
          <motion.video
            src="https://www.yesbpo.com/wp-content/uploads/2025/01/APPCENTER-4.0-1.mp4"
            className="w-full max-w-sm rounded-lg shadow-xl"
            autoPlay
            loop
            muted
          />
        </motion.div>
      </motion.div>

      {/* Button Section */}
      <div className="flex justify-center mt-5">
        <button
          onClick={() => setModalMail(true)}
          className="w-full max-w-md bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full shadow-lg transition-all duration-300"
        >
          Solicita tu demo AppCenter 4.0
        </button>
      </div>

      {/* Background Animation Elements */}
      <motion.div
        className="absolute -z-10 w-64 h-64 bg-blue-100 rounded-full blur-3xl"
        animate={{
          x: [0, 100, 0],
          y: [0, 50, 0],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: 'easeInOut',
        }}
        style={{ top: '10%', left: '10%' }}
      />

      <motion.div
        className="absolute -z-10 w-48 h-48 bg-green-100 rounded-full blur-3xl"
        animate={{
          x: [0, -70, 0],
          y: [0, 40, 0],
          opacity: [0.2, 0.4, 0.2],
        }}
        transition={{
          duration: 10,
          repeat: Infinity,
          ease: 'easeInOut',
        }}
        style={{ bottom: '10%', right: '10%' }}
      />
    </div>
  );
};

export default Marcas;
