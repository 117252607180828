export const gtagReportConversion = url => {
  const callback = () => {
    if (typeof url !== 'undefined') {
      window.location = url;
    }
  };

  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-11562988465/DQXWCNGQ9ZYaELHn1Ikr',
      value: 1.0,
      currency: 'COP',
      event_callback: callback,
    });
  }

  return false;
};
