import React from 'react';
import { Check, X } from 'lucide-react';
import {
  FaWhatsapp,
  FaFacebookMessenger,
  FaInstagram,
  FaWeixin,
  FaLine,
  FaViber,
  FaTelegramPlane,
  FaSms,
  FaEnvelope,
} from 'react-icons/fa';

const Compara = () => {
  const features = [
    {
      name: 'Configuración de grupos',
      withRespond: true,
      withoutRespond: 'Limitado',
    },
    {
      name: (
        <a
          href="https://faq.whatsapp.com/859240711908360/?locale=es_LA&cms_platform=web"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          Configuración perfiles de WhatsApp
        </a>
      ),
      withRespond: true,
      withoutRespond: 'Limitado',
    },
    {
      name: 'Usuarios',
      withRespond: true,
      withoutRespond: 'Básico o limitado',
    },
    {
      name: (
        <a
          href="https://faq.whatsapp.com/1791149784551042/?locale=es_LA&cms_platform=android"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          Respuestas rápidas
        </a>
      ),
      withRespond: true,
      withoutRespond: false,
    },
    {
      name: (
        <a
          href="https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          Plantillas
        </a>
      ),
      withRespond: true,
      withoutRespond: 'Limitado',
    },
    {
      name: 'Creador de Chatbots',
      withRespond: true,
      withoutRespond: false,
    },
    {
      name: (
        <a
          href="https://faq.whatsapp.com/414631957536067/?locale=es_LA"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          Blacklist
        </a>
      ),
      withRespond: true,
      withoutRespond: 'Limitado o Ninguno',
    },
    {
      name: (
        <a
          href="https://business.whatsapp.com/blog/use-whatsapp-business-goals"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          Envios
        </a>
      ),
      withRespond: true,
      withoutRespond: 'Limitado o Ninguno',
    },
    {
      name: 'Reportes',
      withRespond: true,
      withoutRespond: 'Limitado',
    },
    {
      name: 'Monitoreo',
      withRespond: true,
      withoutRespond: 'Limitado',
    },
  ];

  const IconMap = {
    whatsapp: (
      <div className="w-8 h-8 rounded-full bg-green-500 flex items-center justify-center text-white">
        <FaWhatsapp className="text-lg" />
      </div>
    ),
    messenger: (
      <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white">
        <FaFacebookMessenger className="text-lg" />
      </div>
    ),
    instagram: (
      <div className="w-8 h-8 rounded-full bg-pink-500 flex items-center justify-center text-white">
        <FaInstagram className="text-lg" />
      </div>
    ),
    wechat: (
      <div className="w-8 h-8 rounded-full bg-green-600 flex items-center justify-center text-white">
        <FaWeixin className="text-lg" />
      </div>
    ),
    line: (
      <div className="w-8 h-8 rounded-full bg-green-400 flex items-center justify-center text-white">
        <FaLine className="text-lg" />
      </div>
    ),
    viber: (
      <div className="w-8 h-8 rounded-full bg-purple-500 flex items-center justify-center text-white">
        <FaViber className="text-lg" />
      </div>
    ),
    telegram: (
      <div className="w-8 h-8 rounded-full bg-blue-400 flex items-center justify-center text-white">
        <FaTelegramPlane className="text-lg" />
      </div>
    ),
    sms: (
      <div className="w-8 h-8 rounded-full bg-gray-500 flex items-center justify-center text-white">
        <FaSms className="text-lg" />
      </div>
    ),
    email: (
      <div className="w-8 h-8 rounded-full bg-red-500 flex items-center justify-center text-white">
        <FaEnvelope className="text-lg" />
      </div>
    ),
  };

  const renderValue = value => {
    if (Array.isArray(value)) {
      return (
        <div className="flex gap-2 flex-wrap">
          {value.map((item, index) => (
            <div key={index}>{IconMap[item]}</div>
          ))}
        </div>
      );
    } else if (typeof value === 'boolean') {
      return value ? (
        <Check className="text-blue-500 w-6 h-6" />
      ) : (
        <X className="text-gray-400 w-6 h-6" />
      );
    } else {
      return <span className="text-sm">{value}</span>;
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4 font-poppins">
      <h1 className="text-3xl font-bold text-center mb-8">
        Funciones avanzadas con AppCenter 4.0
      </h1>

      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="grid grid-cols-3 bg-gray-800 text-white p-4">
          <div className="col-span-1"></div>
          <div className="col-span-1 text-center">con AppCenter 4.0</div>
          <div className="col-span-1 text-center">Otras soluciones</div>
        </div>

        <div className="divide-y divide-gray-200">
          {features.map((feature, index) => (
            <div key={index} className="grid grid-cols-3 p-4">
              <div className="col-span-1 font-medium text-black">
                {feature.name}
              </div>
              <div className="col-span-1 flex justify-center text-black">
                {renderValue(feature.withRespond)}
              </div>
              <div className="col-span-1 flex justify-center text-black">
                {renderValue(feature.withoutRespond)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Compara;
