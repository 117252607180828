import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { Mail, Phone } from 'lucide-react';
import { gtagReportConversion } from '../utils/gtag';

const countries = [
  { code: '+54', name: 'Argentina' },
  { code: '+61', name: 'Australia' },
  { code: '+591', name: 'Bolivia' },
  { code: '+55', name: 'Brasil' },
  { code: '+1', name: 'Canadá' },
  { code: '+56', name: 'Chile' },
  { code: '+57', name: 'Colombia' },
  { code: '+506', name: 'Costa Rica' },
  { code: '+53', name: 'Cuba' },
  { code: '+593', name: 'Ecuador' },
  { code: '+503', name: 'El Salvador' },
  { code: '+34', name: 'España' },
  { code: '+33', name: 'Francia' },
  { code: '+502', name: 'Guatemala' },
  { code: '+504', name: 'Honduras' },
  { code: '+39', name: 'Italia' },
  { code: '+52', name: 'México' },
  { code: '+505', name: 'Nicaragua' },
  { code: '+507', name: 'Panamá' },
  { code: '+595', name: 'Paraguay' },
  { code: '+51', name: 'Perú' },
  { code: '+44', name: 'Reino Unido' },
  { code: '+1', name: 'República Dominicana' },
  { code: '+1', name: 'Puerto Rico' },
  { code: '+1', name: 'USA' },
  { code: '+598', name: 'Uruguay' },
  { code: '+58', name: 'Venezuela' },
];

const Land = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    country: countries[0].code,
    celular: '',
    email: '',
    motivo: 'Solicitud de informacion AppCenter 4.0',
    mensaje: '',
  });
  const [userIP, setUserIP] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');

  useEffect(() => {
    const getUserIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setUserIP(data.ip);
      } catch (error) {
        console.error('Error obteniendo la IP:', error);
      }
    };
    getUserIP();
  }, []);

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = e => {
    e.preventDefault();

    const fullPhoneNumber = `${formData.country}${formData.celular}`;

    const templateParams = {
      ...formData,
      celular: fullPhoneNumber, // Send full phone number with country code
      fecha: new Date().toLocaleDateString(),
      hora: new Date().toLocaleTimeString(),
      url: window.location.href,
      userAgent: navigator.userAgent,
      ip: userIP,
    };

    emailjs
      .send(
        'service_9hqo149',
        'template_g3gf40a',
        templateParams,
        'wTKz5D5D0Qw4suEN6'
      )
      .then(
        () => {
          setFormData({
            nombre: '',
            country: countries[0].code,
            celular: '',
            email: '',
            motivo: 'Solicitud de informacion AppCenter 4.0',
            mensaje: '',
          });
          setMensajeExito('¡Formulario enviado con éxito!');
          setTimeout(() => setMensajeExito(''), 3000);
          gtagReportConversion();
        },
        () => {
          setMensajeExito('Hubo un error al enviar el formulario.');
          setTimeout(() => setMensajeExito(''), 3000);
        }
      );
  };

  return (
    <div className="min-h-screen ">
      <header className="py-8">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl md:text-5xl text-gray-800 mb-4">
            Revoluciona tu Negocio con APPCENTER
          </h2>
          <h3 className="text-xl text-gray-600">
            Maximiza tu atención al cliente con la solución más completa de
            WhatsApp Business
          </h3>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row gap-8 items-stretch">
          {/* Imagen Principal */}
          <div className="lg:w-3/5 ">
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
              <img
                src="https://www.yesbpo.com/wp-content/uploads/2025/01/WhatsApp-Image-2025-01-27-at-11.49.33-AM-1-1.jpeg"
                alt="WhatsApp Business Platform"
                className="w-full h-full object-cover "
              />
            </div>
          </div>

          {/* Formulario y Características */}
          <div className="lg:w-2/5 space-y-6">
            <div className="bg-white rounded-2xl  p-6">
              <h3 className="text-2xl text-gray-800 mb-6 text-center">
                Características Principales
              </h3>
              <ul className="space-y-4 mb-8">
                {[
                  <>
                    Gestión simultánea de{' '}
                    <strong>múltiples conversaciones</strong> para mayor
                    eficiencia.
                  </>,
                  <>
                    <strong>
                      Creador de bots integrado{' '}
                      <strong className="text-xl font-medium font-bold ">
                        sin costo adicional
                      </strong>
                      .
                    </strong>
                  </>,
                  <>
                    <strong>Creador de plantillas</strong> directamente desde la
                    plataforma.{' '}
                    <a
                      href="https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 hover:underline"
                    >
                      Ver guías
                    </a>
                  </>,
                  <>
                    <strong>Dashboards de control</strong> y seguimiento para
                    monitorear su rendimiento en tiempo real.
                  </>,
                  <>
                    <strong>Integración avanzada</strong> con CRM y otras
                    plataformas.
                  </>,
                ].map((feature, index) => (
                  <li key={index} className="flex items-start rounded-lg">
                    <span className="text-blue-600 mr-3">✔</span>
                    <span className="text-gray-700">{feature}</span>
                  </li>
                ))}
              </ul>

              {mensajeExito && (
                <div className="bg-green-100 border-l-4 border-green-500 p-4 mb-6 text-green-700">
                  {mensajeExito}
                </div>
              )}
              <div className="flex justify-center">
                <img
                  src="https://www.yesbpo.com/wp-content/uploads/2025/01/WhatsApp-Image-2025-01-27-at-11.49.32-AM-Photoroom.png"
                  alt="WhatsApp"
                  style={{
                    width: '160px',
                    height: '160px',
                    marginRight: '10px',
                  }}
                />
              </div>
              <h3 className="text-xl  text-gray-800 mb-6 text-center">
                Obtenga una Demostración Gratuita completando el formulario:
              </h3>

              <form onSubmit={sendEmail} className="space-y-4">
                <input
                  className="w-full px-4 py-3 bg-white border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
                  type="text"
                  name="nombre"
                  placeholder="Nombre Completo"
                  value={formData.nombre}
                  onChange={handleChange}
                  required
                />
                <div className="flex items-center space-x-2">
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    className="w-1/3 px-4 py-3 bg-white border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
                  >
                    {countries.map((country, index) => (
                      <option
                        key={`${country.code}-${country.name}-${index}`}
                        value={country.code}
                      >
                        {country.code} - {country.name}
                      </option>
                    ))}
                  </select>
                  <input
                    className="w-2/3 px-4 py-3 bg-white border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
                    type="tel"
                    name="celular"
                    placeholder="Celular"
                    value={formData.celular}
                    onChange={handleChange}
                    required
                  />
                </div>
                <input
                  className="w-full px-4 py-3 bg-white border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
                  type="email"
                  name="email"
                  placeholder="Correo Electrónico"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />

                <textarea
                  className="w-full px-4 py-3 bg-white border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
                  name="mensaje"
                  placeholder="Mensaje"
                  value={formData.mensaje}
                  onChange={handleChange}
                  rows="4"
                  required
                />
                <button
                  type="submit"
                  className=" btn w-full text-white font-semibold bg-blue-600 rounded-lg  "
                >
                  Enviar
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className=" text-center">
          <p className="text-gray-600 mb-4">
            O comuníquese directamente con nosotros:
          </p>
          <div className="flex justify-center space-x-8">
            <a
              href="tel:+573044575182"
              className="text-blue-600 hover:text-blue-800"
            >
              <Phone size={34} />
            </a>
            <a
              href="https://wa.me/573214743580"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800"
            >
              <img
                src="https://www.yesbpo.com/wp-content/uploads/2025/01/whatsapp-873316_1280.png"
                alt="WhatsApp"
                className="h-8"
              />
            </a>
            <a
              href="mailto:director.ventas@yesbpo.co"
              className="text-blue-600 hover:text-blue-800"
            >
              <Mail size={34} />
            </a>
          </div>
          <div>
            <a
              href="https://wa.me/573214743580"
              className="text-custom-blue hover:underline"
            >
              +57 311 2844485
            </a>{' '}
            📞
            <a
              href="tel:+573108167555"
              className="text-custom-blue hover:underline"
            >
              +57 310 8167555
            </a>{' '}
            ✉️
            <a
              href="mailto:director.ventas@yesbpo.co"
              className="text-custom-blue hover:underline"
            >
              director.ventas@yesbpo.co
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Land;
