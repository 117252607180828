import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { Send, Loader } from 'lucide-react';
import { gtagReportConversion } from '../utils/gtag';

const countries = [
  { code: '+54', name: 'Argentina' },
  { code: '+61', name: 'Australia' },
  { code: '+591', name: 'Bolivia' },
  { code: '+55', name: 'Brasil' },
  { code: '+1', name: 'Canadá' },
  { code: '+56', name: 'Chile' },
  { code: '+57', name: 'Colombia' },
  { code: '+506', name: 'Costa Rica' },
  { code: '+53', name: 'Cuba' },
  { code: '+593', name: 'Ecuador' },
  { code: '+503', name: 'El Salvador' },
  { code: '+34', name: 'España' },
  { code: '+33', name: 'Francia' },
  { code: '+502', name: 'Guatemala' },
  { code: '+504', name: 'Honduras' },
  { code: '+39', name: 'Italia' },
  { code: '+52', name: 'México' },
  { code: '+505', name: 'Nicaragua' },
  { code: '+507', name: 'Panamá' },
  { code: '+595', name: 'Paraguay' },
  { code: '+51', name: 'Perú' },
  { code: '+44', name: 'Reino Unido' },
  { code: '+1', name: 'República Dominicana' },
  { code: '+1', name: 'Puerto Rico' },
  { code: '+1', name: 'USA' },
  { code: '+598', name: 'Uruguay' },
  { code: '+58', name: 'Venezuela' },
];

const CorreosN = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    country: '+57', // Default to Colombia
    celular: '',
    email: '',
    motivo: 'Solitud de informacion AppCenter 4.0',
    mensaje: '',
  });
  const [userIP, setUserIP] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const getUserIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      setUserIP(data.ip);
    } catch (error) {
      console.error('Error obteniendo la IP:', error);
    }
  };

  useEffect(() => {
    getUserIP();
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (formData.nombre.length < 3)
      newErrors.nombre = 'El nombre debe tener al menos 3 caracteres';
    if (formData.celular.trim() === '')
      newErrors.celular = 'Por favor ingrese un número de teléfono';
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
      newErrors.email = 'Ingresa un email válido';
    if (formData.mensaje.length < 10)
      newErrors.mensaje = 'El mensaje debe tener al menos 10 caracteres';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const sendEmail = async e => {
    gtagReportConversion();
    e.preventDefault();

    if (!validateForm()) return;

    setIsLoading(true);

    const templateParams = {
      ...formData,
      // Concatenate country code with phone number
      celular: `${formData.country}${formData.celular}`,
      fecha: new Date().toLocaleDateString(),
      hora: new Date().toLocaleTimeString(),
      url: window.location.href,
      userAgent: navigator.userAgent,
      ip: userIP,
    };

    try {
      await emailjs.send(
        'service_9hqo149',
        'template_g3gf40a',
        templateParams,
        'wTKz5D5D0Qw4suEN6'
      );

      // Reset form
      setFormData({
        nombre: '',
        country: '+57',
        celular: '',
        email: '',
        motivo: 'Solitud de informacion AppCenter 4.0',
        mensaje: '',
      });
      setMensajeExito('¡Formulario enviado con éxito!');
    } catch (error) {
      console.error('Error al enviar el email:', error);
      setMensajeExito('Hubo un error al enviar el formulario.');
    } finally {
      setIsLoading(false);
      setTimeout(() => setMensajeExito(''), 3000);
    }
  };

  const inputClasses = `
    bg-white 
    border-2 
    border-gray-300 
    rounded-lg 
    w-full 
    p-3
    transition-all 
    duration-200
    focus:outline-none 
    focus:border-cyan-500 
    focus:ring-2 
    focus:ring-cyan-200
  `;

  return (
    <div className="max-w-2xl mx-auto p-4">
      <form
        onSubmit={sendEmail}
        className="bg-white shadow-lg rounded-xl overflow-hidden"
      >
        <div className="p-6 space-y-6">
          <h2 className="text-2xl font-bold text-gray-800 text-center mb-6">
            Formulario de Contacto
          </h2>

          <div className="space-y-4">
            <div>
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="nombre"
              >
                Nombre
              </label>
              <input
                className={`${inputClasses} ${
                  errors.nombre ? 'border-red-500' : ''
                }`}
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                placeholder="Tu nombre completo"
                required
              />
              {errors.nombre && (
                <p className="text-red-500 text-sm mt-1">{errors.nombre}</p>
              )}
            </div>

            <div>
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="celular"
              >
                Celular
              </label>
              <div className="flex space-x-2">
                <select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className={`
                    w-1/3 
                    ${inputClasses}
                    ${errors.celular ? 'border-red-500' : ''}
                  `}
                >
                  {countries.map(country => (
                    <option key={country.code} value={country.code}>
                      {country.code} - {country.name}
                    </option>
                  ))}
                </select>
                <input
                  className={`
                    w-2/3 
                    ${inputClasses} 
                    ${errors.celular ? 'border-red-500' : ''}
                  `}
                  type="tel"
                  name="celular"
                  value={formData.celular}
                  onChange={handleChange}
                  placeholder="# de celular"
                  required
                />
              </div>
              {errors.celular && (
                <p className="text-red-500 text-sm mt-1">{errors.celular}</p>
              )}
            </div>

            <div>
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className={`${inputClasses} ${
                  errors.email ? 'border-red-500' : ''
                }`}
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="tucorreo@ejemplo.com"
                required
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            <div>
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="mensaje"
              >
                Mensaje
              </label>
              <textarea
                className={`${inputClasses} ${
                  errors.mensaje ? 'border-red-500' : ''
                }`}
                name="mensaje"
                value={formData.mensaje}
                onChange={handleChange}
                rows="4"
                placeholder="¿En qué podemos ayudarte?"
                required
              />
              {errors.mensaje && (
                <p className="text-red-500 text-sm mt-1">{errors.mensaje}</p>
              )}
            </div>
          </div>

          <button
            className={`
              w-full 
              flex 
              items-center 
              justify-center 
              gap-2 
              py-3 
              rounded-lg 
              font-medium 
              text-white 
              transition-colors 
              ${
                isLoading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-cyan-600 hover:bg-cyan-700'
              }
            `}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Loader className="animate-spin" />
                Enviando...
              </>
            ) : (
              <>
                <Send size={20} />
                Enviar mensaje
              </>
            )}
          </button>

          {mensajeExito && (
            <div
              className={`
              mt-4 
              p-4 
              rounded-lg 
              text-center 
              font-medium 
              ${
                mensajeExito.includes('error')
                  ? 'bg-red-100 text-red-700'
                  : 'bg-green-100 text-green-700'
              }
            `}
            >
              {mensajeExito}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default CorreosN;
