import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import CorreosN from '../correo';
const HeaderA = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [ModalMail, setModalMail] = useState(false);
  return (
    <header className="fixed top-0 w-full z-50 bg-white shadow-sm">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16 md:h-20">
          {/* Logo Section */}
          <div className="flex items-center space-x-4">
            <img
              src="https://www.yesbpo.com/wp-content/uploads/2025/01/973-552-max-1.png  "
              alt="Yes BPO Logo"
              className="h-10 w-auto md:h-7"
            />
            <h1 className="text-lg text-gray">WhatsApp AppCenter 4.0</h1>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex space-x-8">
            <a
              href="https://wa.me/573044575370"
              className="block text-gray-700 hover:text-gray-900 transition"
            >
              Soporte
            </a>
            <a
              href="https://wa.me/573214743580"
              className="block text-gray-700 hover:text-gray-900 transition"
            >
              Ventas
            </a>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                setModalMail(true);
              }}
              className="block text-gray-700 hover:text-gray-900 transition"
            >
              Contáctenos
            </a>
          </div>
          {ModalMail && (
            <div
              style={{ overflow: 'auto' }}
              className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 "
            >
              <div
                style={{
                  maxWidth: '90vw',
                  maxHeight: '90vh',
                  overflowY: 'auto',
                }}
                className="bg-white  rounded-lg relative border-2 border-blue-500"
              >
                <button
                  onClick={() => setModalMail(false)}
                  className="btn btn-circle fixed top-10  text-3xl text-gray-500 hover:text-gray-700"
                >
                  <X size={24} />
                </button>
                <CorreosN />
              </div>
            </div>
          )}
          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded-md text-gray hover:text-gray-900 hover:bg-gray-100 transition"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden mt-2 space-y-2">
            <a
              href="https://wa.me/573044575370"
              className="block text-gray-700 hover:text-gray-900 transition"
            >
              Soporte
            </a>
            <a
              href="https://wa.me/573214743580"
              className="block text-gray-700 hover:text-gray-900 transition"
            >
              Ventas
            </a>
            <a
              href="mailto:director.ventas@yesbpo.co"
              className="block text-gray-700 hover:text-gray-900 transition"
            >
              Contáctenos
            </a>
          </div>
        )}
      </nav>
    </header>
  );
};

export default HeaderA;
